import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import '../../utils/yupIntlSetup';
import * as yup from 'yup';

import Card from '../../components/Card';
import messages from '../messages/searchFlightForm';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import Main from '../../components/Main';
import Container from '../../components/Container';
import Aside from '../../components/Aside';
import StepNavigation from '../../components/StepNavigation';
import { steps, useTranslatedNavigate } from '../../utils/routes';
import routes from '../../messages/routes';
import RadioGroup from '../../components/fields/RadioGroup';
import FormikPersist from '../../components/fields/FormikPersist';
import { removeCustomerFlights } from '../../services/GlobalStore/reducer';
import Count from '../../components/fields/Count';
import PlaneIcon from '../../assets/icons/plane-icon-black.inline.svg';
import StepTitle from '../../components/StepTitle';
import TrustBox from '../../components/TrustBox';
import SocialProof from '../../components/SocialProof';
import SocialProofMobile from '../../components/SocialProof/Mobile';

const validationSchema = yup.object().shape({
  alternative_flights_count: yup
    .number()
    .min(0)
    .required(),
});

// eslint-disable-next-line react/no-multi-comp
const AlternativeFlight = () => {
  const [, dispatch] = useGlobalState();

  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  const handleSubmit = useCallback(({ alternativeFlight }) => {
    if (alternativeFlight === 'yes') {
      translatedNavigate(`alternative-flights/1`);
    } else {
      translatedNavigate(routes.checkCompensation);
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Used when 'No' is selected.
   * Alternative flight is removed
   * @param value
   * @param setFieldValue
   */
  function radioCallback(value, { setFieldValue }) {
    if (value === 'no') {
      dispatch(removeCustomerFlights(0, 'alternative'));
      setFieldValue('alternative_flights_count', 0);
    } else {
      setFieldValue('alternative_flights_count', 1);
    }
  }

  function navigateBack() {
    translatedNavigate(routes.itinerary);
  }

  const alternativeFlightRadioOptions = [
    {
      value: 'yes',
      label: messages.alternativeFlightOptionYes,
    },
    {
      value: 'no',
      label: messages.alternativeFlightOptionNo,
    },
  ];

  return (
    <Formik
      initialValues={{ alternative_flights_count: 0 }}
      onSubmit={handleSubmit}
      isInitialValid={false}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Container>
          <Main>
            <Form>
              <Card>
                <StepTitle>
                  {formatMessage(messages.alternativeFlightQuestionLabel)}
                </StepTitle>
                <RadioGroup
                  headerLabel
                  row
                  border
                  name={`alternativeFlight`}
                  options={alternativeFlightRadioOptions}
                  callback={radioCallback}
                  extraDescription={formatMessage(
                    messages.alternativeFlightQuestionExtraDescription
                  )}
                  wrapperClassName={'sm:max-w-none'}
                />
                {values?.alternativeFlight === 'yes' && (
                  <div className="mt-45">
                    <Count
                      name="alternative_flights_count"
                      label={messages.alternativeFlightCountLabel}
                      headerLabel
                      className="mb-50 sm:max-w-none"
                      icon={<PlaneIcon />}
                    />
                  </div>
                )}
              </Card>
              <StepNavigation
                onBackClick={navigateBack}
                backButtonMessage={formatMessage(
                  messages.alternativeFlightSubmitBack
                )}
                submitButtonMessage={formatMessage(
                  messages.continueAlternativeFlight
                )}
                dataLayerPushOnBack
                step={steps.AlternativeFlight}
                dataLayerPushValueOnSubmit={'alternativeFlight'}
              />
              <TrustBox />
              <SocialProofMobile />
              <FormikPersist localStorageKey={'alternativeFlightCount'} />
            </Form>
          </Main>
          <Aside showSocialProof>
            <SocialProof>{formatMessage(messages.asideText)}</SocialProof>
          </Aside>
        </Container>
      )}
    </Formik>
  );
};

AlternativeFlight.propTypes = {};

export default AlternativeFlight;
