// React specific
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import Card from '../../components/Card';
import Main from '../../components/Main';
import Container from '../../components/Container';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import StepTitle from '../../components/StepTitle';
import FlightSearch from '../FlightSearch';

// Routes
import routes from '../../messages/routes';
import messages from '../messages/searchFlightForm';
import { useTranslatedNavigate } from '../../utils/routes';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';

// Icons
import connectingFlight from '../../assets/icons/connecting-flight-icon.svg';
import PassengerIcon from '../../assets/icons/passenger-icon.inline.svg';
import FlightAirportInformationStripe from '../FlightAirportInformationStripe';
import { CustomerFlightModel } from '../../models/CustomerFlightModel';
import SubTitleWithIcon from '../PersonalInfo/SubTitleWithIcon';

import SocialProof from '../../components/SocialProof';
import TrustBox from '../../components/TrustBox';
import SocialProofMobile from '../../components/SocialProof/Mobile';

// eslint-disable-next-line react/no-multi-comp
const SearchConnectingFlight = ({
  arrivalAirport,
  departureAirport,
  flightIndex,
  flightsCount,
  flightType,
}: any) => {
  const arrivalAirportId = arrivalAirport?.id ?? null;
  const departureAirportId = departureAirport?.id ?? null;

  const customerFlightModel: CustomerFlightModel = {
    departureAirport,
    arrivalAirport,
  };
  const customerFlightsModel: CustomerFlightModel[] = [customerFlightModel];

  const [flights] = useGlobalState(`customerFlights.${flightType}`);
  const { formatMessage } = useIntl();
  const translatedNavigate = useTranslatedNavigate();

  useEffect(() => {
    if (flightIndex > 0 && !flights?.[flightIndex - 1]) {
      if (flightType === 'booked') {
        translatedNavigate(`/flights/${flightIndex}`);
      } else if (flightType === 'alternative') {
        translatedNavigate(`/alternative-flights/${flightIndex}`);
      }
    }
    // eslint-disable-next-line
  }, []);

  function handleSubmit() {
    if (flightIndex < flightsCount - 1) {
      if (flightType === 'booked')
        translatedNavigate(`/flights/${flightIndex + 2}`);
      else if (flightType === 'alternative')
        translatedNavigate(`/alternative-flights/${flightIndex + 2}`);
    } else {
      if (flightType === 'booked') translatedNavigate(['/', routes.itinerary]);
      else if (flightType === 'alternative')
        translatedNavigate(['/', routes.checkCompensation]);
    }
  }

  function navigateBack() {
    if (flightIndex > 0) {
      if (flightType === 'booked')
        translatedNavigate(`/flights/${flightIndex}`);
      else if (flightType === 'alternative')
        translatedNavigate(`/alternative-flights/${flightIndex}`);
    } else {
      if (flightType === 'booked') translatedNavigate('/');
      else if (flightType === 'alternative') {
        translatedNavigate(['/', routes.alternativeFlight]);
      }
    }
  }

  return (
    <Container>
      <Main>
        <Card onlyDesktop={true}>
          <StepTitle>
            {formatMessage(
              flightType === 'alternative'
                ? messages.alternativeFlightHeader
                : messages.connectingFlightHeader,
              {
                index: flightIndex,
              }
            )}
          </StepTitle>
          <Card onlyMobile={true}>
            {flightType === 'alternative' ? (
              <SubTitleWithIcon
                as="h2"
                className="sm:border-b pb-5 border-gray-200"
                icon={null}
              >
                {formatMessage(messages.alternativeFlightSubtitle, {
                  flightIndex: flightIndex + 1,
                })}
              </SubTitleWithIcon>
            ) : (
              <FlightAirportInformationStripe
                flights={customerFlightsModel}
                borderBottom={true}
              />
            )}
            <FlightSearch
              step={`search-${flightType}-flight-${flightIndex + 1}`}
              flightType={flightType}
              flightIndex={flightIndex}
              navigateNext={handleSubmit}
              navigateBack={navigateBack}
              fixedAirports={
                flightType === 'alternative'
                  ? undefined
                  : [departureAirportId, arrivalAirportId]
              }
            />
          </Card>
        </Card>
        <div id="delegateSubmit" />
      </Main>
      <Aside showSocialProof={true}>
        <SocialProof>
          {flightType === 'alternative' ? (
            <PassengerIcon className="mb-20" />
          ) : (
            <Icon src={connectingFlight} className="mb-20" />
          )}
          {formatMessage(
            flightType === 'alternative'
              ? messages.alternativeFlightAsideText
              : messages.connectingFlightAsideText
          )}
        </SocialProof>
      </Aside>
      <TrustBox />
      <SocialProofMobile />
    </Container>
  );
};

SearchConnectingFlight.propTypes = {
  arrivalAirport: PropTypes.object,
  departureAirport: PropTypes.object,
  flightIndex: PropTypes.number.isRequired,
  flightType: PropTypes.oneOf(['booked', 'alternative']).isRequired,
  flightsCount: PropTypes.number.isRequired,
};

export default SearchConnectingFlight;
