import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchConnectingFlight from '../SearchFlightsForm/SearchConnectingFlight';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { useGlobalState } from '../../services/GlobalStore/GlobalStore';
import { useTranslatedNavigate } from '../../utils/routes';

export const getFlightAirports = (route, airports, flightIndex) => {
  const [departureAirportId, arrivalAirportId] = route.slice(
    flightIndex,
    flightIndex + 2
  );
  return [airports[departureAirportId], airports[arrivalAirportId]];
};

const EnterConnectingFlights = ({ ...props }) => {
  // As humans see urls flights/:id starting from 1, we make it readable by devs
  let flightIndex = parseInt(props.flightIndex) - 1;

  const [values] = useLocalStorageState('flight-type-form');
  const translatedNavigate = useTranslatedNavigate();
  const { departure_airport, stopovers, arrival_airport } = values || {};
  const [airports] = useGlobalState('airports');

  useEffect(() => {
    if (!departure_airport || !stopovers || !arrival_airport) {
      translatedNavigate('/');
    }
    // eslint-disable-next-line
  }, []);

  // Construct airport sequence from values
  const route = [departure_airport, ...(stopovers || []), arrival_airport];
  if (!route.filter(Boolean).length) {
    return null;
  }

  const [departureAirport, arrivalAirport] = getFlightAirports(
    route,
    airports,
    flightIndex
  );

  if (departureAirport && arrivalAirport) {
    return (
      <SearchConnectingFlight
        key={`connecting-flight-${flightIndex}`}
        departureAirport={departureAirport}
        arrivalAirport={arrivalAirport}
        flightIndex={flightIndex}
        flightsCount={route?.length - 1}
        flightType={'booked'}
      />
    );
  }

  return null;
};

EnterConnectingFlights.propTypes = {
  flightIndex: PropTypes.string,
  navigate: PropTypes.func,
};

export default EnterConnectingFlights;
