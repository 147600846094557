import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchConnectingFlight from '../SearchFlightsForm/SearchConnectingFlight';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

const EnterAlternativeFlights = ({ navigate, ...props }) => {
  // As humans see urls flights/:id starting from 1, we make it readable by devs
  let flightIndex = parseInt(props.flightIndex) - 1;

  const [
    { alternative_flights_count, alternativeFlight },
  ] = useLocalStorageState('alternativeFlightCount');

  useEffect(() => {
    if (!alternativeFlight) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SearchConnectingFlight
      key={`alternative-flight-${flightIndex}`}
      flightIndex={flightIndex}
      flightsCount={alternative_flights_count}
      navigate={navigate}
      flightType={'alternative'}
    />
  );
};

EnterAlternativeFlights.propTypes = {
  flightIndex: PropTypes.string,
  navigate: PropTypes.func,
};

export default EnterAlternativeFlights;
