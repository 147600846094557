import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SubTitle = ({
  as: Element,
  className,
  children,
  elementProps,
  icon: Icon,
  iconClassName,
  width,
}) => (
  <Element className={clsx('flex items-center', className)} {...elementProps}>
    {Icon ? (
      <Icon
        className={clsx(iconClassName, 'mr-5')}
        {...elementProps}
        width={width}
      />
    ) : null}
    {children}
  </Element>
);

SubTitle.defaultProps = {
  as: 'h2',
};

SubTitle.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  elementProps: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconClassName: PropTypes.string,
  width: PropTypes.number,
};

export default SubTitle;
