import { defineMessages } from 'react-intl';

export default defineMessages({
  seoTitle: {
    id: 'cc4.seo.negative-advice.title',
    defaultMessage: '',
  },
  seoDescription: {
    id: 'cc4.seo.negative-advice.description',
    defaultMessage: '',
  },
  negativeAdviceCompensationTitle: {
    id: 'cc4.form.negative-advice.compensation-title',
    defaultMessage:
      'Unfortunately you are not entitled to receive compensation for this flight',
  },
  negativeAdviceTicketRefundTitle: {
    id: 'cc4.form.negative-advice.ticket-refund-title',
    defaultMessage:
      'Unfortunately you are not entitled to receive a refund for this flight',
  },
  backButtonText: {
    id: 'cc4.form.negative-advice.button.back',
    defaultMessage: 'Back',
  },
  submitContinue: {
    id: 'cc4.form.negative-advice.button.submit-continue',
    defaultMessage: 'Check another flight',
  },
  asideText: {
    id: 'cc4.form.negative-advice.aside-text',
    defaultMessage:
      'Airlines are legally required to pay compensation for disrupted flights up to three years old',
  },
});
