import React, { useState } from 'react';

// Components
import SearchFlightsForm from '../views/SearchFlightsForm';
import EnterConnectingFlights from '../views/EnterConnectingFlights';
import Itinerary from '../views/Itinerary';
import AlternativeFlight from '../views/AlternativeFlight';
import CompensationCheck from '../views/CompensationCheck';
import NegativeAdvice from '../views/NegativeAdvice';
import PageTransition from '../components/PageTransition';
import routes from '../messages/routes';
import EnterAlternativeFlights from '../views/EnterAlternativeFlights';
import NotFoundPage from './404';

const IndexPage = () => {
  // this is needed because of hydration mismatch https://github.com/gatsbyjs/gatsby/issues/15159
  // Reloading in a nested client route can lead to incorrect rehydration
  const [hasMounted, setHasMounted] = useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return (
    <>
      {!hasMounted ? null : (
        <PageTransition basepath={'/'} hideMenu showStepper={true}>
          <SearchFlightsForm path="/" />
          <EnterConnectingFlights path={'flights/:flightIndex'} />
          <Itinerary path={routes.itinerary} />
          <AlternativeFlight path={routes.alternativeFlight} />
          <EnterAlternativeFlights path={'alternative-flights/:flightIndex'} />
          <CompensationCheck path={routes.checkCompensation} />
          <NegativeAdvice path={routes.sorry} />
          <NotFoundPage default />
        </PageTransition>
      )}
    </>
  );
};

export default IndexPage;
