import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import Container from '../../components/Container';
import Main from '../../components/Main';
import Card from '../../components/Card';
import StepTitle from '../../components/StepTitle';
import Button from '../../components/Button';
import Aside from '../../components/Aside';
import Icon from '../../components/Icon';
import Seo from '../../components/seo';
import StepObserver from '../../components/StepObserver';

// Icons
import infoIcon from '../../assets/icons/info-icon-grey.svg';

// Utils
import { useLocalStorageState } from '../../hooks/useLocalStorageState';

// Services
import {
  FLIGHT_COMPENSATION,
  TICKET_REFUND,
  PRODUCT_TYPE,
} from '../../services/appLocalStorage';

// Translations
import messages from './messages';
import useDataLayer from '../../hooks/useDataLayer';
import { steps, useTranslatedNavigate } from '../../utils/routes';

const NegativeAdvice = ({ location }) => {
  const { formatMessage } = useIntl();
  const [productType] = useLocalStorageState(PRODUCT_TYPE);
  const translatedNavigate = useTranslatedNavigate();
  const [pushEvent] = useDataLayer();

  function nextStep() {
    pushEvent({
      eventLabel: 'continue',
      eventAction: steps.Sorry,
    });
    translatedNavigate('/', { state: {}, replace: true });
  }

  return (
    <Container>
      <StepObserver stepName={steps.Sorry} />
      <Seo title={messages.seoTitle} description={messages.seoDescription} />
      <Main>
        <Card onlyDesktop>
          <StepTitle>
            {productType === FLIGHT_COMPENSATION &&
              formatMessage(messages.negativeAdviceCompensationTitle)}
            {productType === TICKET_REFUND &&
              formatMessage(messages.negativeAdviceTicketRefundTitle)}
          </StepTitle>
          <p className="sm:hidden mb-35 sm:mt-0 mx-10 sm:mx-0 text-black">
            {formatMessage(messages.asideText)}
          </p>
          <Card onlyMobile>
            <p className="mb-35 sm:mt-0 mx-10 m-0 sm:mx-0 text-black">
              {location?.state?.description}
            </p>
          </Card>
        </Card>
        <Button
          className="mx-15 sm:mx-0 my-25 sm:my-40 mb-30 w-auto sm:w-auto"
          theme="primary"
          type="button"
          onClick={nextStep}
        >
          {formatMessage(messages.submitContinue)}
        </Button>
      </Main>
      <Aside>
        <Icon src={infoIcon} className="mb-20" />
        {formatMessage(messages.asideText)}
      </Aside>
    </Container>
  );
};

NegativeAdvice.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NegativeAdvice;
